import React from 'react';
import Box from '@mui/material/Box';
import { css } from '@emotion/react';
import clsx from 'clsx';
import intl from 'react-intl-universal';

import type { SpinnerProps } from '~/components/ui/Spinner/type';
import Spinner from '~/components/ui/Spinner';

const spinnerDisplayContainer = () => {
  return css({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });
};

interface SpinnerDisplayProps extends SpinnerProps {}

const SpinnerDisplay = (props: SpinnerDisplayProps) => {
  const { tips = intl.get('加载中'), className = '', ...rest } = props;
  return (
    <Box css={spinnerDisplayContainer} className={clsx(className)}>
      <Spinner tips={tips} {...rest} />
    </Box>
  );
};

export default SpinnerDisplay;
