/* 4bf11c68273e08b650624b5204a68b00db3b88d6
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetRsaKeyMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRsaKeyMutation = (
  { __typename?: 'Mutation' }
  & { rsaKeyCreateOne?: Types.Maybe<(
    { __typename?: 'RsaKey' }
    & Pick<Types.RsaKey, 'expiration' | 'publicKey' | 'uuid'>
  )> }
);

export type GetTokenMutationVariables = Types.Exact<{
  input?: Types.Maybe<Types.TokenAuthReqInput>;
}>;


export type GetTokenMutation = (
  { __typename?: 'Mutation' }
  & { tokenLogin?: Types.Maybe<(
    { __typename?: 'Token' }
    & { data: (
      { __typename?: 'TokenData' }
      & Pick<Types.TokenData, 'id' | 'name' | 'etag' | 'uuid' | 'valid' | 'userId' | 'projectId' | 'expireAt' | 'createdAt' | 'updatedAt'>
      & { deletedAt?: Types.Maybe<(
        { __typename?: 'DeletedAt' }
        & Pick<Types.DeletedAt, 'time' | 'valid'>
      )> }
    ) }
  )> }
);

export type TokenLogoutMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type TokenLogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'tokenLogout'>
);


export const GetRsaKeyDocument = gql`
    mutation GetRsaKey {
  rsaKeyCreateOne {
    expiration
    publicKey
    uuid
  }
}
    `;
export type GetRsaKeyMutationFn = Apollo.MutationFunction<GetRsaKeyMutation, GetRsaKeyMutationVariables>;

/**
 * __useGetRsaKeyMutation__
 *
 * To run a mutation, you first call `useGetRsaKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetRsaKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getRsaKeyMutation, { data, loading, error }] = useGetRsaKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetRsaKeyMutation(baseOptions?: Apollo.MutationHookOptions<GetRsaKeyMutation, GetRsaKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetRsaKeyMutation, GetRsaKeyMutationVariables>(GetRsaKeyDocument, options);
      }
export type GetRsaKeyMutationHookResult = ReturnType<typeof useGetRsaKeyMutation>;
export type GetRsaKeyMutationResult = Apollo.MutationResult<GetRsaKeyMutation>;
export type GetRsaKeyMutationOptions = Apollo.BaseMutationOptions<GetRsaKeyMutation, GetRsaKeyMutationVariables>;
export const GetTokenDocument = gql`
    mutation GetToken($input: TokenAuthReqInput) {
  tokenLogin(tokenAuthReqInput: $input) {
    data {
      id
      name
      etag
      uuid
      valid
      userId
      projectId
      expireAt
      createdAt
      updatedAt
      deletedAt {
        time
        valid
      }
    }
  }
}
    `;
export type GetTokenMutationFn = Apollo.MutationFunction<GetTokenMutation, GetTokenMutationVariables>;

/**
 * __useGetTokenMutation__
 *
 * To run a mutation, you first call `useGetTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getTokenMutation, { data, loading, error }] = useGetTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTokenMutation(baseOptions?: Apollo.MutationHookOptions<GetTokenMutation, GetTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetTokenMutation, GetTokenMutationVariables>(GetTokenDocument, options);
      }
export type GetTokenMutationHookResult = ReturnType<typeof useGetTokenMutation>;
export type GetTokenMutationResult = Apollo.MutationResult<GetTokenMutation>;
export type GetTokenMutationOptions = Apollo.BaseMutationOptions<GetTokenMutation, GetTokenMutationVariables>;
export const TokenLogoutDocument = gql`
    mutation TokenLogout {
  tokenLogout
}
    `;
export type TokenLogoutMutationFn = Apollo.MutationFunction<TokenLogoutMutation, TokenLogoutMutationVariables>;

/**
 * __useTokenLogoutMutation__
 *
 * To run a mutation, you first call `useTokenLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenLogoutMutation, { data, loading, error }] = useTokenLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useTokenLogoutMutation(baseOptions?: Apollo.MutationHookOptions<TokenLogoutMutation, TokenLogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TokenLogoutMutation, TokenLogoutMutationVariables>(TokenLogoutDocument, options);
      }
export type TokenLogoutMutationHookResult = ReturnType<typeof useTokenLogoutMutation>;
export type TokenLogoutMutationResult = Apollo.MutationResult<TokenLogoutMutation>;
export type TokenLogoutMutationOptions = Apollo.BaseMutationOptions<TokenLogoutMutation, TokenLogoutMutationVariables>;