import { css } from '@emotion/react';

import themeCSSObj from '~/styles/theme.module.scss';

export const ButtonClasses = {
  container: 'CharonButton-container',
  root: 'CharonButton-root',
};

export const ButtonStyle = () => {
  return css({
    '&.MuiButton-outlinedPrimary': {
      background: themeCSSObj.appFillColor3,
      border: `1px solid ${themeCSSObj.appFillColor3} !important`,
      '&:hover': {
        background: themeCSSObj.appFillColor3,
        border: `1px solid ${themeCSSObj.appFillColor3}`,
        boxShadow: 'none',
      },
      '&:active': {
        background: themeCSSObj.appFillColor4,
        boxShadow: 'none',
      },
    },
    '&.MuiButton-textPrimary, &.MuiButton-textSecondary': {
      '&:hover': {
        background: themeCSSObj.appFillColor3,
      },
      '&:active': {
        background: themeCSSObj.appFillColor4,
      },
    },
  });
};

export const iconStyle = css({
  display: 'block',
  background: 'none',
  outline: 'none',
  border: 'none',
  position: 'relative',
  transition: 'all 0.2s',
  padding: '0',
  fontSize: '0',
  borderRadius: themeCSSObj.appShapeBorderRadius3,
});
