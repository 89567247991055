import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import { Dialog } from '@mui/material';
import clsx from 'clsx';
import { css } from '@emotion/react';

import { default as dialogModel } from '~/lib/dialog';
import ps from '~/lib/ps';
import forms from '~/components/forms';
import widgets from '~/components/widgets';
import { POP_DIALOG, CLEAR_DIALOG, PUSH_DIALOG } from '~/constants/ps-events';
import { DIALOG_MAX_HEIGHT } from '~/constants';
import globalCSSObj from '~/styles/global.module.scss';
import type { Dialog as DialogType } from '~/lib/dialog';
import ConfirmDialog from '~/components/common/ConfirmDialog';
import ScrollArea from '~/eris-ui/eris-components/components/ScrollArea';
import workflow from '~/lib/workflow';
import themeStyle from '~/styles/theme.module.scss';

import { DialogClasses, getWidthStyle } from './lib';
import { ExtraState } from '../ConfirmDialog/type';

export default function DialogStack() {
  const [dialogs, setDialogs] = useState<DialogType[]>([]);

  function handlePop() {
    setDialogs((preDialogs) => preDialogs.slice(0, dialogs.length - 1));
  }

  function handleClear() {
    setDialogs([]);
  }

  function handlePush(message: string, dialog: DialogType) {
    setDialogs((preDialogs) => [...preDialogs, dialog]);
  }

  useEffect(() => {
    const pushToken = ps.subscribe(PUSH_DIALOG, handlePush);
    const popToken = ps.subscribe(POP_DIALOG, handlePop);
    const clearToken = ps.subscribe(CLEAR_DIALOG, handleClear);

    return function cleanup() {
      ps.unsubscribe(pushToken);
      ps.unsubscribe(popToken);
      ps.unsubscribe(clearToken);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderInner(dialog: DialogType) {
    const { type, workflowId } = dialog;
    function handleCancel() {
      if (workflowId) {
        workflow.publish(workflowId, { action: 'cancel' });
      }
    }

    function handleFinish() {
      if (workflowId) {
        workflow.publish(workflowId, { action: 'finish' });
      }
    }

    if (type === 'form') {
      const { formData } = dialog;

      const Form: any = forms[dialog.form];
      return <Form {...formData} onCancel={handleCancel} onConfirm={handleFinish}></Form>;
    }

    if (type === 'widget') {
      const { widgetData } = dialog;

      const Widget: any = widgets[dialog.widget];
      return <Widget {...widgetData} onCancel={handleCancel} onConfirm={handleFinish} />;
    }

    if (type === 'confirm') {
      // ScrollArea 内部结构，需要覆盖样式
      const scrollAreaClass = css({
        ['& > div > div']: {
          display: 'block !important',
        },
      });
      return (
        <ScrollArea maxHeight={`calc(100vh - ${DIALOG_MAX_HEIGHT}px)`} css={scrollAreaClass}>
          <ConfirmDialog
            {...dialog}
            onClose={() => dialogModel.pop()}
            onCancel={
              dialog.onCancel
                ? () => {
                    handleCancel();
                    return dialog.onCancel?.();
                  }
                : undefined
            }
            onConfirm={
              dialog.onConfirm
                ? (extraParameter: Record<string, boolean>, extraState: ExtraState) => {
                    handleFinish();
                    return dialog.onConfirm?.(extraParameter, extraState);
                  }
                : undefined
            }
          />
        </ScrollArea>
      );
    }
  }

  return (
    <>
      {dialogs.map((dialog, index) => {
        const defaultDisableEsc = dialog.fullScreen ? true : false;

        const { _id, fullScreen = false, width, disableEsc = defaultDisableEsc, type } = dialog;

        let showTopNavi = true;
        let top;

        if (type === 'form' || type === 'widget') {
          showTopNavi = dialog.showTopNavi ?? true;
        } else {
          top = dialog.top;
        }

        const isLastDialog = index === dialogs.length - 1;
        const isLastFullscreen =
          index === lodash.findLastIndex(dialogs, (d) => d.fullScreen === true);
        const dialogVisibility = (isLastDialog || isLastFullscreen) ? 'visible' : 'hidden';
        const dialogTop = fullScreen ? (showTopNavi ? globalCSSObj.topNaviHeight : 0) : 0;
        return (
          <Dialog
            key={_id}
            PaperProps={{
              className: clsx(DialogClasses['paper']),
            }}
            open={true}
            fullScreen={fullScreen}
            onClose={(e, reason) => {
              if (reason === 'escapeKeyDown' && !disableEsc) {
                dialogModel.pop();
              }
            }}
            sx={{ top: dialogTop, visibility: dialogVisibility }}
            BackdropProps={{ sx: { top: dialogTop, visibility: dialogVisibility } }}
            css={[
              getWidthStyle(fullScreen, width || 'medium', top),
              {
                // If full screen is applied, the z-index should be just lower than
                // top navi (app bar), so that the launch box can cover this full screen dialog.
                zIndex: fullScreen ? +themeStyle.zIndexAppBar - 1 : undefined,
              },
            ]}
          >
            {renderInner(dialog)}
          </Dialog>
        );
      })}
    </>
  );
}
