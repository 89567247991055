import React from 'react';
import { Box, DialogTitle } from '@mui/material';

import IconButton from '~/components/ui/IconButton';
import Typography from '~/components/ui/Typography';

import type { DialogTitleProps } from './type';

export default function Title(props: DialogTitleProps) {
  const { children, onClose } = props;

  return (
    <DialogTitle
      sx={{ padding: '24px 32px' }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography size="s3" fontWeight={600} type="t1">
        {children}
      </Typography>
      {onClose ? (
        <Box>
          <IconButton icon="Close16" onClick={onClose} />
        </Box>
      ) : null}
    </DialogTitle>
  );
}
