var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';
// tailwind-merge 有些主题定制不支持，需要自定义
// https://github.com/dcastil/tailwind-merge/blob/v1.12.0/docs/configuration.md#theme
var twMergeCustom = extendTailwindMerge({
    classGroups: {
        'font-size': [
            'text-body',
            'text-title',
            'text-caption',
            'text-subhead',
            'text-number',
            'text-bignumber',
        ],
        boxShadow: [
            'elevation-1-bottom',
            'elevation-2-left',
            'elevation-2-right',
            'elevation-2-top',
            'elevation-3-right',
            'interactive-danger-active',
            'interactive-primary-focus',
            'interactive-danger-focus',
            'interactive-success-focus',
            'interactive-primary-active',
            'elevation-2-bottom',
            'interactive-success-active',
            'elevation-3-bottom',
        ],
    },
});
export function cn() {
    var inputs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        inputs[_i] = arguments[_i];
    }
    return twMergeCustom(clsx(inputs));
}
export var reorder = function (list, sourceIndex, destinationIndex) {
    var temp = __spreadArray([], list, true);
    var target = temp[sourceIndex];
    temp.splice(sourceIndex, 1);
    temp.splice(destinationIndex, 0, target);
    return temp;
};
export function useFitContentWidth(_a) {
    var label = _a.label, fontSize = _a.fontSize, fontWeight = _a.fontWeight;
    var width = React.useMemo(function () {
        // not browser env
        if (typeof window === 'undefined' || !label) {
            return 0;
        }
        var span = document.createElement('span');
        span.innerText = label !== null && label !== void 0 ? label : '';
        span.style.opacity = '0';
        span.style.padding = '0px';
        span.style.position = 'absolute';
        span.style.fontSize = "".concat(fontSize, "px");
        span.style.fontWeight = "".concat(fontWeight);
        // 保留空格
        span.style.whiteSpace = 'pre';
        window.document.body.appendChild(span);
        var width = span.offsetWidth;
        window.document.body.removeChild(span);
        return width + 1; // + 1 防止小数点时会省略
    }, [fontSize, fontWeight, label]);
    return width;
}
export var uuid = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0, v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
export var getToday = function () {
    return new Date();
};
export function useFocus() {
    var _a = React.useState(false), focused = _a[0], setFocused = _a[1];
    var onFocus = React.useCallback(function () {
        setFocused(true);
    }, []);
    var onBlur = React.useCallback(function () {
        setFocused(false);
    }, []);
    return { focused: focused, onFocus: onFocus, onBlur: onBlur };
}
