// Owner: Anqi Shu
// Link: https://www.figma.com/file/hhGypwqtngDETGqb1WZkkr/Loading%E8%A7%84%E8%8C%83

import React from 'react';
import { Box, Fade, CircularProgress } from '@mui/material';
import clsx from 'clsx';

import Typography from '~/components/ui/Typography';

import {
  sizeList,
  thicknessList,
  tipsStyle,
  spinningBackgroundStyle,
  spinningContainerStyle,
  spinningBoxStyle,
  spinningCircularBox,
  spinningCircularStyle,
  getAnimationStyle,
} from './lib';
import type { SpinnerProps } from './type';

const Spinner = (props: SpinnerProps) => {
  const {
    tips,
    size = 'medium',
    spinning = true,
    children,
    effect = 'dark',
    className,
    ...rest
  } = props;

  return (
    <Box
      css={spinningContainerStyle(!!children)}
      className={clsx('CharonSpinner-container', className)}
      {...rest}
    >
      <Fade
        in={spinning}
        easing={{ enter: 'ease-in', exit: 'ease-out' }}
        css={getAnimationStyle(!!children)}
        timeout={500}
      >
        <Box>
          <Box
            css={spinningBackgroundStyle(!!children)}
            className={clsx('CharonSpinner-background')}
          />
          <Box className="CharonSpinner-spinning" css={spinningBoxStyle(!!children, size)}>
            <Box css={spinningCircularBox(size)}>
              <CircularProgress
                variant="determinate"
                css={spinningCircularStyle(effect, 0.3)}
                value={100}
                size={sizeList[size]}
                thickness={thicknessList[size]}
              />
              <CircularProgress
                css={spinningCircularStyle(effect, 1)}
                size={sizeList[size]}
                thickness={thicknessList[size]}
              />
            </Box>
            {tips && (
              <Typography
                type={effect === 'dark' ? 't2' : 't4'}
                css={[tipsStyle(size, effect, tips)]}
              >
                {tips}
              </Typography>
            )}
          </Box>
        </Box>
      </Fade>
      {children}
    </Box>
  );
};

export default Spinner;
