/*
 * Lib for Dialog Stack
 */

import { css } from '@emotion/react';

import type { Dialog } from '~/lib/dialog';

const DialogClasses = {
  paper: 'PDialog-paper',
};

function getWidthStyle(
  fullScreen: boolean,
  width: Dialog['width'],
  top: string | number | undefined
) {
  if (fullScreen) return css({});

  if (width === 'small') {
    return css({
      [`& .${DialogClasses['paper']}`]: {
        position: 'absolute',
        top: top,
        margin: 0,
        width: '360px',
        maxWidth: '360px',
      },
    });
  } else if (width === 'medium') {
    return css({
      [`& .${DialogClasses['paper']}`]: {
        position: 'absolute',
        top: top,
        margin: 0,
        width: '500px',
        maxWidth: '500px',
      },
    });
  } else if (width === 'large') {
    return css({
      [`& .${DialogClasses['paper']}`]: {
        position: 'absolute',
        top: top,
        margin: 0,
        width: '800px',
        maxWidth: '800px',
      },
    });
  }

  return css({});
}

export { getWidthStyle, DialogClasses };
