// "next/dynamic" wrapper supports retry
// Author: zhengjin@xsky.com
// Usage: dynamicWithRetry(() => import('./VirtualMachineForm/Create'))

import { ComponentType } from 'react';
import intl from 'react-intl-universal';
import Box from '@mui/material/Box';
import dynamic, { DynamicOptions, DynamicOptionsLoadingProps, Loader } from 'next/dynamic';
import Link from '@mui/material/Link';

import SpinnerDisplay from '~/components/common/SpinnerDisplay';
import Typography from '~/components/ui/Typography';

type Options<P> = DynamicOptions<P> | Loader<P>;

function dynamicWithRetry<P = Record<string, unknown>>(
  asyncFn: Options<P>,
): ComponentType<P> {
  return dynamic(
    asyncFn,
    {
      loading: ({ error, retry, timedOut }: DynamicOptionsLoadingProps) => {
        if (error || timedOut) {
          let msg = !timedOut
            ? intl.get('组件加载失败，请')
            : intl.get('组件加载超时，请');
          return (
            <Box margin="auto">
              <Typography>
                <span>{msg}</span>
                <Link href="#" onClick={retry}>{intl.get('点击重试')}</Link>
                <span>{intl.get('。')}</span>
              </Typography>
            </Box>
          );
        }
        return <SpinnerDisplay size="large" />;
      },
      ssr: false,
    },
  );
}

export default dynamicWithRetry;