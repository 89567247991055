/*
 * Check Password
 */

import React from 'react';
import { Box, OutlinedInput } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import intl from 'react-intl-universal';
import lodash from 'lodash';

import Typography from '~/components/ui/Typography';
import { useUserVerifyPasswordMutation } from '~/__generated__/graphql/app/operations/users.graphql';
import { useGetRsaKeyMutation } from '~/__generated__/graphql/app/operations/login.graphql';
import { rsaEncrypt } from '~/lib/rsa-encrypt';
import themeCSSObj from '~/styles/theme.module.scss';

interface CheckPasswordPropsType {
  onOk: () => void;
  onError: () => void;
}

function CheckPassword(props: CheckPasswordPropsType) {
  const { onOk, onError } = props;

  const [getRsa] = useGetRsaKeyMutation();
  const [verifyPassword] = useUserVerifyPasswordMutation();

  const rsaReqControl = React.useRef<AbortController>();
  const verifyReqControl = React.useRef<AbortController>();

  async function confirm(myPassword: string) {
    if (rsaReqControl.current) rsaReqControl.current.abort();
    if (verifyReqControl.current) verifyReqControl.current.abort();

    rsaReqControl.current = new AbortController();
    verifyReqControl.current = new AbortController();

    try {
      const rsaResponse = await getRsa({
        context: {
          fetchOptions: {
            signal: rsaReqControl.current.signal,
          },
        },
      });
      if (!rsaResponse.data?.rsaKeyCreateOne) throw new Error('getRsa error');

      const { uuid, publicKey } = rsaResponse.data?.rsaKeyCreateOne;

      await verifyPassword({
        variables: {
          input: {
            data: {
              password: rsaEncrypt(myPassword, publicKey),
              rsaKeyUuid: uuid,
            },
          },
        },
        context: {
          fetchOptions: {
            signal: verifyReqControl.current.signal,
          },
        },
      });
      onOk();
    } catch (error) {
      // the error is an Error (not AbortError), hack with message content
      if ((error as Error).message.includes('aborted')) return;
      onError();
    }
  }

  return (
    <Box data-class="check-password">
      <Typography>{intl.get('该操作存在一定风险，请输入当前账户密码进行操作确认。')}</Typography>
      <OutlinedInput
        placeholder={intl.get('请输入密码')}
        type="password"
        sx={{
          marginTop: '4px',
          width: '100%',
          height: '34px',
          borderRadius: themeCSSObj.appShapeBorderRadius1,
          [`& .${outlinedInputClasses['input']}`]: {
            padding: '0 8px',
            color: themeCSSObj.appFontColor2,
          },
          [`& .${outlinedInputClasses['notchedOutline']}`]: {
            borderColor: themeCSSObj.appFillColor4,
          },
        }}
        onChange={lodash.debounce(function (event) {
          const value = event.target.value;
          confirm(value);
        }, 500)}
        data-e2e="check-password"
      />
    </Box>
  );
}

export default CheckPassword;
