import { css } from '@emotion/react';
import intl from 'react-intl-universal';
import { circularProgressClasses } from '@mui/material/CircularProgress';

import { getPrimaryColorExtension, getWhiteColorExtension, ColorExtensionType } from '~/lib/color';

import { Size, Effect } from './type';
export const sizeList = {
  small: 16,
  medium: 32,
  large: 64,
};

export const thicknessList = {
  small: 5,
  medium: 4,
  large: 4,
};

export const getAnimationStyle = (children: boolean) => {
  return css([
    { lineHeight: 1 },
    children && { position: 'absolute', width: '100%', height: '100%', zIndex: 190 },
  ]);
};

export const spinningContainerStyle = (hasChildren: boolean) => {
  return css({
    position: 'relative',
    width: hasChildren ? '100%' : '',
    display: hasChildren ? '' : 'inline-block',
  });
};

export const spinningBackgroundStyle = (hasChildren: boolean) => {
  return hasChildren
    ? css({
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: getWhiteColorExtension(0.8),
        zIndex: 190,
        textAlign: 'center',
        lineHeight: 1,
      })
    : css({
        display: 'inline',
        textAlign: 'center',
        lineHeight: 1,
      });
};

export const spinningBoxStyle = (hasChildren: boolean, size: Size) => {
  const basicCss = css({
    display: 'flex',
    flexDirection: size === 'small' ? 'row' : 'column',
    alignItems: 'center',
  });
  return hasChildren
    ? css([
        {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 191,
        },
        basicCss,
      ])
    : basicCss;
};

export const spinningCircularBox = (size: Size) => {
  return css({ width: sizeList[size], height: sizeList[size], position: 'relative' });
};

export const spinningCircularStyle = (effect: Effect, value: ColorExtensionType) => {
  return css({
    color: effect === 'dark' ? getPrimaryColorExtension(value) : getWhiteColorExtension(value),
    position: 'absolute',
    left: 0,
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round',
    },
  });
};

export const tipsStyle = (size: Size, effect: Effect, tips: string) => {
  return css([
    {
      fontSize: size === 'large' ? '16px' : '14px',
      wordBreak: 'keep-all',
      position: 'relative',
      textAlign: 'center',
      marginTop: size !== 'small' ? '8px' : '0px',
      marginLeft: size === 'small' ? '4px' : '0px',
      display: size === 'small' ? 'inline-block' : '',
    },
    intl.get(tips) === '加载中' && ellipsisStyle(),
  ]);
};

export const ellipsisStyle = () => {
  return css({
    ':after': {
      // eslint-disable-next-line quotes
      content: "'...'",
      position: 'absolute',
      top: 0,
    },
  });
};
