import React, { useCallback, useRef } from 'react';

import Button from '~/components/ui/Button';

import { getModeProps } from './lib';
import type { FunctionButtonProps } from './type';

const FunctionalButton = (props: FunctionButtonProps) => {
  const { mode = 'create', children, onClick, throttle = 1000, ...rest } = props;

  const throttleTag = useRef<boolean>(false);

  const throttleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (throttleTag.current) return;
      throttleTag.current = true;
      onClick?.(e);
      setTimeout(() => {
        throttleTag.current = false;
      }, throttle);
    },
    [throttle, onClick]
  );

  const modeProps = getModeProps(mode);
  return (
    <Button {...modeProps} {...rest} onClick={throttleClick}>
      {children ? children : modeProps.children}
    </Button>
  );
};

export default FunctionalButton;
