'use client';
import React from 'react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { cva } from 'class-variance-authority';
import { cn } from '../../lib/utils';
var ThumbCornerWidth = 2;
var ScrollbarClass = 'box-border flex touch-none select-none bg-transparent transition-all duration-200 ease-out';
var ScrollbarVerticalVariant = cva(cn(ScrollbarClass, 'z-50 py-[4px] pl-[4px] pr-[2px] hover:pl-[2px]'), {
    variants: {
        thumbSize: {
            thin: cn('w-[10px]'),
            default: cn('w-[12px]'),
        },
    },
});
var ScrollbarHorizontalVariant = cva(cn(ScrollbarClass, 'z-50 flex-col px-[4px] pb-[2px] pt-[4px] hover:pt-[2px]'), {
    variants: {
        thumbSize: {
            thin: cn('h-[10px]'),
            default: cn('h-[12px]'),
        },
    },
});
var thumbClass = 'bg-grey-1050 relative flex-1 rounded-xl opacity-20 hover:opacity-50';
var ScrollArea = React.forwardRef(function (_a, ref) {
    var _b = _a.width, width = _b === void 0 ? 'auto' : _b, _c = _a.height, height = _c === void 0 ? 'auto' : _c, _d = _a.maxHeight, maxHeight = _d === void 0 ? '100%' : _d, _e = _a.maxWidth, maxWidth = _e === void 0 ? '100%' : _e, color = _a.color, _f = _a.thumbSize, thumbSize = _f === void 0 ? 'default' : _f, _g = _a.disabledHorizontal, disabledHorizontal = _g === void 0 ? false : _g, _h = _a.disabledVertical, disabledVertical = _h === void 0 ? false : _h, _j = _a.forceMountVertical, forceMountVertical = _j === void 0 ? false : _j, onScroll = _a.onScroll, className = _a.className, children = _a.children;
    return (<ScrollAreaPrimitive.Root scrollHideDelay={300} className={cn('overflow-hidden', className)} style={{ width: width, height: height, maxWidth: maxWidth }}>
        <ScrollAreaPrimitive.Viewport className="h-full w-full" ref={ref} onScroll={onScroll} style={{
            maxHeight: maxHeight,
        }}>
          {children}
        </ScrollAreaPrimitive.Viewport>
        {!disabledVertical && (<ScrollAreaPrimitive.Scrollbar orientation="vertical" className={ScrollbarVerticalVariant({ thumbSize: thumbSize })} forceMount={!forceMountVertical ? undefined : true} style={{ bottom: ThumbCornerWidth }}>
            <ScrollAreaPrimitive.Thumb className={thumbClass} style={{ backgroundColor: color }}/>
          </ScrollAreaPrimitive.Scrollbar>)}
        {!disabledHorizontal && (<ScrollAreaPrimitive.Scrollbar orientation="horizontal" className={ScrollbarHorizontalVariant({ thumbSize: thumbSize })} forceMount style={{ right: ThumbCornerWidth }}>
            <ScrollAreaPrimitive.Thumb className={thumbClass} style={{ backgroundColor: color }}/>
          </ScrollAreaPrimitive.Scrollbar>)}
      </ScrollAreaPrimitive.Root>);
});
ScrollArea.displayName = 'ScrollArea';
export default ScrollArea;
