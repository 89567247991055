/*
 * widgets
 *
 * This module exports widgets which can be used globally.
 */

import dynamicWithRetry from '~/lib/dynamicWithRetry';

const widgets = {
  StoragePoolCreationProcessingWidget: dynamicWithRetry(
    () => import('~/pages-ui/storagePool/List/CreationProcessing')
  ),
  StoragePoolAddingProcessingWidget: dynamicWithRetry(
    () => import('~/pages-ui/storagePool/List/AddingProcessing')
  ),
  DeveloperSettingWidget: dynamicWithRetry(() => import('~/components/common/DeveloperSetting')),
  VncLoginWidget: dynamicWithRetry(() => import('~/components/common/Vnc/VncLogin')),
  Protocol: dynamicWithRetry(() => import('~/components/common/Protocol')),
  AboutProduct: dynamicWithRetry(() => import('~/components/common/AboutProduct')),
};

export default widgets;
