import dynamicWithRetry from '~/lib/dynamicWithRetry';

const forms = {
  BrNetFormCreate: dynamicWithRetry(() => import('./BrNetForm/BrNetFormCreate')),
  BrNetFormEdit: dynamicWithRetry(() => import('./BrNetForm/BrNetFormEdit')),
  BrNetFormChangeIpam: dynamicWithRetry(() => import('./BrNetForm/BrNetFormChangeIpam')),
  EmailConfigForm: dynamicWithRetry(() => import('./EmailConfigForm')),
  VmImageCreateForm: dynamicWithRetry(() => import('./VmImageForm/Create')),
  VmImageCreateByVmForm: dynamicWithRetry(() => import('./VmImageForm/CreateByVM')),
  VirtualMachineForm: dynamicWithRetry(() => import('./VirtualMachineForm/Create')),
  VirtualMachineUpdateBasicMsgForm: dynamicWithRetry(
    () => import('./VirtualMachineForm/Update/BasicMessage')
  ),
  VirtualMachineUpdateStorageForm: dynamicWithRetry(
    () => import('./VirtualMachineForm/Update/Storage')
  ),
  VirtualMachineUpdateNetworkForm: dynamicWithRetry(
    () => import('./VirtualMachineForm/Update/Network')
  ),
  VirtualMachineUpdateCalculationForm: dynamicWithRetry(
    () => import('./VirtualMachineForm/Update/Calculation')
  ),
  VirtualMachineHotMigrateForm: dynamicWithRetry(
    () => import('./VirtualMachineForm/Migrate/HotMigrate')
  ),
  VirtualMachineUpdateSchedulingPolicyForm: dynamicWithRetry(
    () => import('./VirtualMachineForm/Update/SchedulingPolicy')
  ),
  VirtualMachineUpdateSnapPolicyForm: dynamicWithRetry(
    () => import('./VirtualMachineForm/Update/SnapPolicy')
  ),
  VmSnapCloneForm: dynamicWithRetry(() => import('./VirtualMachineForm/CloneVm/VmSnapClone')),
  VmCloneForm: dynamicWithRetry(() => import('./VirtualMachineForm/CloneVm/VmClone')),
  VmIsolationForm: dynamicWithRetry(() => import('./VirtualMachineForm/Isolation')),
  VmSnapForm: dynamicWithRetry(() => import('./VmSnapForm/Create')),
  VmSnapUpdateForm: dynamicWithRetry(() => import('./VmSnapForm/Update')),
  VswFormCreate: dynamicWithRetry(() => import('./VswForm/VswFormCreate')),
  VswFormEdit: dynamicWithRetry(() => import('./VswForm/VswFormEdit')),
  VswFormAddNic: dynamicWithRetry(() => import('./VswForm/VswFormAddNic')),
  VswFormRemoveNic: dynamicWithRetry(() => import('./VswForm/VswFormRemoveNic')),
  StoragePoolForm: dynamicWithRetry(() => import('./StoragePoolForm')),
  UpdateStoragePoolPolicy: dynamicWithRetry(
    () => import('./StoragePoolForm/updateStoragePoolPolicy')
  ),
  AddOsds: dynamicWithRetry(() => import('./StoragePoolForm/AddOsds')),
  RemoveOsds: dynamicWithRetry(() => import('./StoragePoolForm/removeOsds')),
  RenameForm: dynamicWithRetry(() => import('./RenameForm')),
  UpdateStoragePoolCompression: dynamicWithRetry(
    () => import('./StoragePoolForm/UpdateCompression')
  ),
  UpdateStoragePoolRecoveryQos: dynamicWithRetry(() => import('./StoragePoolForm/RecoveryQos')),
  UpdateStoragePoolOsdCapacityThreshold: dynamicWithRetry(
    () => import('./StoragePoolForm/OsdCapacityThreshold')
  ),
  StoragePoolIOBypass: dynamicWithRetry(() => import('./StoragePoolForm/IOBypass')),
  UpdateDataConsistencyChecking: dynamicWithRetry(
    () => import('./StoragePoolForm/DataConsistencyChecking')
  ),
  CreateVmSchedulingPolicyForm: dynamicWithRetry(
    () => import('./VmSchedulingPolicyForm/CreateVmSchedulingPolicy')
  ),
  EditVmSchedulingPolicy: dynamicWithRetry(
    () => import('./VmSchedulingPolicyForm/EditVmSchedulingPolicy')
  ),
  CreateSnapPolicyForm: dynamicWithRetry(() => import('./SnapPolicyForm/CreateSnapPolicy')),
  EditSnapPolicy: dynamicWithRetry(() => import('./SnapPolicyForm/EditSnapPolicy')),
  StoragePoolReweight: dynamicWithRetry(() => import('./StoragePoolForm/Reweight')),
  FeedbackForm: dynamicWithRetry(() => import('~/components/forms/FeedbackForm')),
  BsPolicyForm: dynamicWithRetry(() => import('~/components/forms/BsPolicyForm/Create')),
  BsVolumeForm: dynamicWithRetry(() => import('~/components/forms/BsVolumeForm/Create')),
  VmImageUpdateForm: dynamicWithRetry(() => import('~/components/forms/VmImageForm/Update')),
  BsRenamePolicyForm: dynamicWithRetry(
    () => import('~/components/forms/BsPolicyForm/RenamePolicy')
  ),
  MonitorAnalysisViewForm: dynamicWithRetry(
    () => import('~/components/forms/MonitorAnalysisForm/View')
  ),
  BsVolumeRenameForm: dynamicWithRetry(() => import('~/components/forms/BsVolumeForm/Rename')),
  BsVolumeCapacityExpansionForm: dynamicWithRetry(
    () => import('~/components/forms/BsVolumeForm/CapacityExpansion')
  ),
  BsPolicyUpdateDefaultConf: dynamicWithRetry(
    () => import('~/components/forms/BsPolicyForm/UpdateDefaultConf')
  ),
  BsVolumeUpdateAdvanceConf: dynamicWithRetry(
    () => import('~/components/forms/BsVolumeForm/UpdateAdvanceConf')
  ),
  MonitorAnalysisChartForm: dynamicWithRetry(
    () => import('~/components/forms/MonitorAnalysisForm/Chart')
  ),
  MonitorAnalysisUpdateChartForm: dynamicWithRetry(
    () => import('~/components/forms/MonitorAnalysisForm/View/EditView')
  ),
  MonitorAnalysisCopyViewForm: dynamicWithRetry(
    () => import('~/components/forms/MonitorAnalysisForm/View/CopyView')
  ),
  MonitorRevertViewForm: dynamicWithRetry(
    () => import('~/components/forms/MonitorAnalysisForm/View/RevertView')
  ),
  MonitorAnalysisEditChartForm: dynamicWithRetry(
    () => import('~/components/forms/MonitorAnalysisForm/Chart/EditChart')
  ),
  MonitorAnalysisCopyChartForm: dynamicWithRetry(
    () => import('~/components/forms/MonitorAnalysisForm/Chart/CopyChart')
  ),
  BsSnapCreateForm: dynamicWithRetry(() => import('~/components/forms/BsSnapForm/CreateBsSnap')),
  BsSnapEditForm: dynamicWithRetry(() => import('~/components/forms/BsSnapForm/EditBsSnap')),
  BsVolumeRollbackForm: dynamicWithRetry(() => import('~/components/forms/BsVolumeForm/Rollback')),
  BsVolumeCloneForm: dynamicWithRetry(
    () => import('~/components/forms/BsVolumeForm/CloneBsVolume')
  ),
  NodeCreateForm: dynamicWithRetry(() => import('~/components/forms/NodeForm/Create')),
  NodeRoleUpdateForm: dynamicWithRetry(() => import('~/components/forms/NodeForm/RoleUpdate')),
  ImageBIOSUpdateForm: dynamicWithRetry(() => import('~/components/forms/VmImageForm/UpdateBIOS')),
  VirtualMachineUpdateAdvanceConfigForm: dynamicWithRetry(
    () => import('./VirtualMachineForm/Update/AdvanceConfig')
  ),
  UpdateReservedMemoryMbForm: dynamicWithRetry(
    () => import('~/components/forms/NodeForm/UpdateReservedMemoryMb')
  ),
  NodeUpdateCpuNumForm: dynamicWithRetry(() => import('~/components/forms/NodeForm/UpdateCpuNum')),
  ClusterHardDiskOfflineTime: dynamicWithRetry(
    () => import('./SystemSettingsForm/ClusterHardDiskOfflineTime')
  ),
  CreateExtSdsClusterForm: dynamicWithRetry(
    () => import('./SystemSettingsForm/ExtSdsCluster/Create')
  ),
  UpdateExtSdsClusterForm: dynamicWithRetry(
    () => import('./SystemSettingsForm/ExtSdsCluster/Update')
  ),
  AccessExtSdsPoolForm: dynamicWithRetry(() => import('./ExtSdsPoolForm/Access')),
  EditExtSdsPoolForm: dynamicWithRetry(() => import('./ExtSdsPoolForm/Edit')),
  GlobalSecurityPolicyEditForm: dynamicWithRetry(
    () => import('./GlobalSecurityPolicyForm/EditForm')
  ),
  SecurityPolicyRuleForm: dynamicWithRetry(() => import('./SecurityPolicyForm/RuleForm')),
  SecurityPolicyPolicyForm: dynamicWithRetry(() => import('./SecurityPolicyForm/PolicyForm')),
  SecurityPolicyBasicEditForm: dynamicWithRetry(() => import('./SecurityPolicyForm/BasicEdit')),
  EditUSB: dynamicWithRetry(() => import('./USBForm/Edit')),
  MountUSB: dynamicWithRetry(() => import('./USBForm/Mount')),
  LabelCreateForm: dynamicWithRetry(() => import('./LabelForm/Create')),
  LabelKeyEditForm: dynamicWithRetry(() => import('./LabelForm/Edit')),
  LabelValueAddForm: dynamicWithRetry(() => import('./LabelForm/AddLabelValue')),
  LabelValueEditForm: dynamicWithRetry(() => import('./LabelForm/EditLabelValue')),
  LabelValueAttachForm: dynamicWithRetry(() => import('./LabelForm/AttachLabelValue')),
  LabelValueDetachForm: dynamicWithRetry(() => import('./LabelForm/DetachLabelValue')),
};

export default forms;
