// Owner: Anqi Shu
// Link: https://www.figma.com/file/EksX02vubOAtfRJNvwQeNv/Overlord-%E8%AE%BE%E8%AE%A1%E8%A7%84%E8%8C%83?node-id=11723%3A60136&t=MviorIqHypK5JY01-1

import React, { forwardRef } from 'react';
import { Button as MuiButton, Box } from '@mui/material';
import clsx from 'clsx';
import * as icons from 'pluto-icon';
import { useTheme } from '@mui/material/styles';

import Icon from '~/charon/Icon';
import Spinner from '~/components/ui/Spinner';
import Tooltip from '~/components/ui/Tooltip';
import { useMounted } from '~/lib/hooks';

import { ButtonProps } from './type';
import { iconStyle, ButtonClasses, ButtonStyle } from './lib';

const Button = forwardRef((props: ButtonProps, ref: React.MutableRefObject<HTMLButtonElement>) => {
  const {
    loading,
    children,
    icon,
    tooltip = '',
    disabled = false,
    variant = 'contained',
    iconViewSize,
    tooltipProps,
    className,
    effect = 'light',
    ...rest
  } = props;

  const theme = useTheme();
  // fix warning in Firefox
  // reference: https://github.com/vercel/next.js/discussions/21999
  const hasMounted = useMounted();
  if (!hasMounted) return null;

  return (
    <Tooltip title={tooltip} placement="top" {...tooltipProps} disableFocusListener>
      <Box
        className={clsx(ButtonClasses['container'], className)}
        css={{ lineHeight: 1, cursor: disabled ? 'not-allowed' : 'default' }}
      >
        <MuiButton
          disabled={loading || disabled}
          css={[effect === 'dark' ? ButtonStyle : '']}
          variant={variant}
          className={ButtonClasses['root']}
          data-e2e="CharonButton"
          ref={ref}
          {...rest}
        >
          {(loading || icon) &&
            (loading ? (
              <Spinner size="small" effect={variant === 'contained' ? 'light' : 'dark'} />
            ) : (
              <Icon
                css={!children && iconStyle}
                name={icon as icons.TIconName}
                viewSize={iconViewSize}
              />
            ))}
          {children && (
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                marginLeft: icon || loading ? theme.spacing(0.5) : '',
              }}
            >
              {children}
            </Box>
          )}
        </MuiButton>
      </Box>
    </Tooltip>
  );
});

export default Button;
