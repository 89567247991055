import NodeRSA from 'node-rsa';
import { Buffer } from 'buffer';

export function rsaEncrypt(item: NodeRSA.Data, publicKey: string) {
  // base64 decode public key
  const publicKeyBase64 = Buffer.from(publicKey, 'base64');
  // rsa
  const key = new NodeRSA();
  key.setOptions({
    environment: 'browser',
    encryptionScheme: { hash: 'sha256', scheme: 'pkcs1_oaep' },
  });
  key.importKey(publicKeyBase64, 'pkcs1-public-der');

  const encrypte = key.encrypt(item, 'base64');

  return encrypte;
}
