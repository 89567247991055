import intl from 'react-intl-universal';

import themeCSSObj from '~/styles/theme.module.scss';

import { ButtonMode } from './type';

export function getModeProps(mode: ButtonMode) {
  return {
    create: {
      color: 'primary' as const,
      children: intl.get('创建'),
    },
    update: {
      color: 'primary' as const,
      children: intl.get('更新'),
    },
    delete: {
      color: 'error' as const,
      children: intl.get('删除'),
    },
    confirm: {
      color: 'primary' as const,
      children: intl.get('确定'),
    },
    cancel: {
      sx: {
        color: themeCSSObj.appFontColor2,
      },
      color: 'secondary' as const,
      children: intl.get('取消'),
      variant: 'text' as const,
    },
  }[mode];
}
