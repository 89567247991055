/**
 * 确认框组件，在DialogStack中使用
 */
import { Box, Checkbox, DialogActions, DialogContent } from '@mui/material';
import React, { useCallback, useState } from 'react';
import intl from 'react-intl-universal';

import Typography from '~/components/ui/Typography';
import FunctionalButton from '~/components/common/FunctionalButton';
import DialogTitle from '~/components/common/DialogTitle';

import CheckPassword from './CheckPassword';
import { ConfirmDialogProps, ExtraState } from './type';

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    title,
    content,
    confirmText = intl.get('确定'),
    confirmBtnProps,
    cancelText = intl.get('取消'),
    cancelBtnProps,
    width = '360px',
    maxWidth = '1920px',
    onConfirm,
    onCancel,
    onClose = () => {},
    autoCloseOnConfirm = true,
    mode,
    checkPassword = false,
    checkRead = false,
    extraParameterOptions = [],
    hiddenAction = false,
    hideCancel = false
  } = props || {};

  const [cancelLoading, setCancelLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(checkPassword);
  const [checkReadChecked, setCheckReadChecked] = React.useState(false);
  const [extraParameter, setExtraParameter] = useState<Record<string, boolean>>(
    extraParameterOptions.reduce((p, v) => Object.assign(p, { [v.key]: v.checked || false }), {})
  );
  const [extraState, setExtraState] = useState<ExtraState>({});

  const handleCancel = () => {
    if (!onCancel) {
      onClose();
      return;
    }
    if (typeof props.onCancel === 'function') {
      const result = props.onCancel() as Promise<unknown>;

      if (result?.then) {
        setCancelLoading(true);
        result?.then(() => {
          setCancelLoading(false);
          onClose();
        });
      } else {
        onClose();
      }
    } else {
      onClose();
    }
  };

  const handleConfirm = () => {
    if (typeof props.onConfirm !== 'function') {
      throw new Error('onConfirm must be a function');
    }

    const result = props.onConfirm(extraParameter, extraState) as Promise<unknown>;

    if (result?.then) {
      setConfirmLoading(true);
      result?.then(() => {
        setConfirmLoading(false);
        autoCloseOnConfirm && onClose();
      }, () => {
        setConfirmLoading(false);
      });
    } else {
      autoCloseOnConfirm && onClose();
    }
  };

  const handleCheck = useCallback(
    (key, value) => {
      setExtraParameter({
        ...extraParameter,
        [key]: value,
      });
    },
    [extraParameter]
  );

  const confirmBtnDisabled = confirmDisabled || (checkRead && !checkReadChecked);

  return (
    <Box minWidth={width} maxWidth={maxWidth}>
      {!!title && <DialogTitle onClose={onClose}>{title}</DialogTitle>}
      {!!content && (
        <DialogContent sx={{ padding: '0 32px' }}>
          <Typography css={{ wordBreak: 'break-all' }}>
            {
              typeof content === 'object' && 'useAdvancedContent' in content
                ? content.useAdvancedContent(function (extraState) {
                    setExtraState(extraState);
                  })
                : content
            }
          </Typography>
        </DialogContent>
      )}

      {checkPassword && (
        <Box sx={{ marginTop: '16px', padding: '0 32px' }}>
          <CheckPassword
            onOk={function () {
              setConfirmDisabled(false);
            }}
            onError={function () {
              setConfirmDisabled(true);
            }}
          />
        </Box>
      )}

      {checkRead && (
        <Box sx={{ marginTop: '8px', padding: '0 24px' }}>
          <Box
            onClick={function () {
              setCheckReadChecked(!checkReadChecked);
            }}
            sx={{
              display: 'inline-flex',
              cursor: 'pointer',
              userSelect: 'none',
            }}
            data-e2e="check-read"
          >
            <Checkbox checked={checkReadChecked} />
            <Typography css={{ marginLeft: '4px' }}>
              {intl.get('我已阅读上述信息，了解执行操作带来的风险。')}
            </Typography>
          </Box>
        </Box>
      )}

      {!hiddenAction && (
        <DialogActions sx={{ padding: '24px 32px' }}>
          <Box marginRight="auto" display="flex">
            {extraParameterOptions.map((option) => (
              <Box display="flex" marginRight={1} key={option.key}>
                <Checkbox
                  defaultChecked={option.checked}
                  onClick={(e) => handleCheck(option.key, (e.target as HTMLInputElement).checked)}
                />
                <Typography type="t1" css={{ marginLeft: '4px' }}>
                  {option.label}
                </Typography>
              </Box>
            ))}
          </Box>

          {!!onCancel && !hideCancel && (
            <FunctionalButton
              loading={cancelLoading}
              variant="text"
              onClick={handleCancel}
              mode="cancel"
              {...cancelBtnProps}
            >
              {cancelText}
            </FunctionalButton>
          )}
          {!!onConfirm && (
            <FunctionalButton
              mode={mode === 'delete' ? 'delete' : 'confirm'}
              loading={confirmLoading}
              variant="contained"
              disableElevation
              disabled={confirmBtnDisabled}
              onClick={handleConfirm}
              data-e2e="PDialogConfirm-button"
              {...confirmBtnProps}
              {...extraState.confirmBtnProps}
            >
              {confirmText}
            </FunctionalButton>
          )}
        </DialogActions>
      )}
    </Box>
  );
}
