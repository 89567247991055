/**
 * 单例的异步工作流的发布订阅中心
 * 通常由按钮触发，触发的可能是表单，确认框，自定义弹窗等，在完成后向外界发布消息，通知外界工作流已完成
 */
import { nanoid } from 'nanoid';
import { useState } from 'react';

import { WORKFLOW } from '~/constants/ps-events';

import ps from './ps';

export type WorkflowAction = 'finish' | 'cancel';

// 用于创建和获取当前工作流的id
export function useWorkflow(): [string | undefined, () => string] {
  const [currentWorkflow, setCurrentWorkflow] = useState<string>();

  function createWorkFlow() {
    const workflowId = `workflow-${nanoid()}`;
    setCurrentWorkflow(workflowId);
    return workflowId;
  }

  return [currentWorkflow, createWorkFlow];
}

function publish(
  id: string,
  data: {
    action?: WorkflowAction;
    payload?: any;
  }
) {
  return ps.publish(WORKFLOW, {
    id,
    action: data.action || 'finish',
    payload: data.payload,
  });
}

function subscribe(
  id: string,
  callback: (props: { action: WorkflowAction; payload: any }) => void
) {
  return ps.subscribe(WORKFLOW, (message, data) => {
    if (data.id === id) {
      callback({
        action: data.action,
        payload: data.payload,
      });
    }
  });
}

function unsubscribe(id: string) {
  return ps.unsubscribe(id);
}

const workflow = {
  publish,
  subscribe,
  unsubscribe,
};

Object.freeze(workflow);

export default workflow;
